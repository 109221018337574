import * as React from 'react'
import Splash from '../../../splash'
import { useMutation } from '@tanstack/react-query'
import { getGroupByEventId } from '../../../services/group'
import { navigate } from 'gatsby'

import { useToast } from '@chakra-ui/react'
import { errorToastOptions } from '../../../helpers'

import NotFoundPage from '../../404'

const JoinGroupByEventId = ({ eventId }) => {
	const toast = useToast()
	const [notFound, setNotFound] = React.useState(false)

	const getGroupByEventIdMutation = useMutation(getGroupByEventId, {
		onSuccess: async (data) => {
			if (data?.id) {
				// Group already exists, so just join it
				navigate(`/groups/${data.id}?autojoin=true`)
			} else {
				setNotFound(true)
			}
		},

		onError: (error) => {
			toast({
				description: error.message || error,
				...errorToastOptions
			})
		}
	})

	React.useEffect(() => {
		getGroupByEventIdMutation.mutate({
			queryKey: ['eventId', eventId]
		})
	}, [])

	if (notFound) {
		return <NotFoundPage />
	}

	return <Splash isLoading={getGroupByEventIdMutation.isLoading} />
}

export default JoinGroupByEventId
